import React from 'react';
import { graphql } from 'gatsby';

import Blog from '../components/Blog/Blog';
import Card from '../components/Card/Card';
import Footer from '../components/Footer/Footer';
import Layout from '../components/Layout';
import Section from '../components/Section/Section';
import Hero from '../components/Hero/Hero';
import ImageGroup from '../components/ImageGroup/ImageGroup';
import MainNavigation from '../components/MainNavigation/MainNavigation';
import RichText from '../components/RichText/RichText';
import SocialLinks from '../components/SocialLinks/SocialLinks';
import Testimonial from '../components/Testimonial/Testimonial';
import HeroSub from '../components/HeroSubPage/HeroSub';
import Video from '../components/Video/Video';
import Widget from '../components/Widget/Widget';

const getComponent = (name) => {
  const components = {
    ContentfulBlog: Blog,
    ContentfulHero: Hero,
    ContentfulSubPageHero: HeroSub,
    ContentfulCard: Card,
    ContentfulImageGroup: ImageGroup,
    ContentfulRichText: RichText,
    ContentfulSocialLinks: SocialLinks,
    ContentfulTestimonial: Testimonial,
    ContentfulVideo: Video,
    ContentfulWidget: Widget,
  };

  const validComponent = Object.keys(components).some((component) => component === name);

  const component = validComponent ? components[name] : undefined;

  return component;
};

interface PageTemplateProps {
  data: {
    navItems: { navItems: [] };
    page: {
      title: string;
      slug: string;
      metaDescription: string;
      body: [{ component: [{ __typename: string }] }];
    };
  };
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  const { title, slug, metaDescription } = data.page;
  const { navItems: mainNavItems } = data.mainNav;
  const { navItems: footerNavItems } = data.footerNav;

  return (
    <Layout title={title} slug={slug} metaDescription={metaDescription}>
      <header>
        <MainNavigation navItems={mainNavItems} activePage={slug} />
      </header>
      <main id="main">
        {data.page.body?.map((value, key) => {
          return (
            <Section key={key} {...value}>
              {value.component?.map((item, index) => {
                const sectionComponentCount = value.component?.length;
                const ComponentNode = getComponent(item.__typename);
                // remove null properties
                Object.keys(item).forEach((prop) => item[prop] === null && delete item[prop]);

                return ComponentNode ? (
                  <ComponentNode {...item} key={index} sectionComponentCount={sectionComponentCount} />
                ) : null;
              })}
            </Section>
          );
        })}
      </main>
      <Footer navItems={footerNavItems} activePage={slug} />
    </Layout>
  );
};

export default PageTemplate;

export const query = graphql`
  query PageBySlugQuery($slug: String!) {
    page: contentfulPage(slug: { eq: $slug }) {
      title
      slug
      metaDescription
      body {
        title
        showTitle
        backgroundColor
        width
        sectionId
        component {
          ... on Node {
            __typename
            id
            ... on ContentfulBlog {
              title
            }
            ... on ContentfulHero {
              ...HeroModel
            }
            ... on ContentfulCard {
              ...Card
            }
            ... on ContentfulVideo {
              ...Video
            }
            ... on ContentfulImageGroup {
              ...ImageGroup
            }
            ... on ContentfulRichText {
              richTextBody {
                json
              }
            }
            ... on ContentfulSocialLinks {
              type
            }
            ... on ContentfulSubPageHero {
              ...HeroSub
            }
            ... on ContentfulTestimonial {
              ...Testimonial
            }
            ... on ContentfulWidget {
              ...Widget
            }
          }
        }
      }
    }
    mainNav: contentfulNavigation(contentful_id: { eq: "2meGLRtPVtQHM3nQ5O9ybD" }) {
      ...NavigationFragment
    }
    footerNav: contentfulNavigation(contentful_id: { eq: "4oCRZPRLFLbMWGwFsXcHjI" }) {
      ...NavigationFragment
    }
  }
`;
